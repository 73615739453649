import { default as about_45passmRX1Q2ScYqMeta } from "/codebuild/output/src1196041050/src/src/pages/about-pass.vue?macro=true";
import { default as about_45yumenaviE9GVIa745wMeta } from "/codebuild/output/src1196041050/src/src/pages/about-yumenavi.vue?macro=true";
import { default as agreementjAi5zbND3aMeta } from "/codebuild/output/src1196041050/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45aliasKa37lpurvEMeta } from "/codebuild/output/src1196041050/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmYpY3U9tmpVMeta } from "/codebuild/output/src1196041050/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45alias3V0h3DDVZTMeta } from "/codebuild/output/src1196041050/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editYtjelT94isMeta } from "/codebuild/output/src1196041050/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as index0ciK2P0queMeta } from "/codebuild/output/src1196041050/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmzUZzOLInjIMeta } from "/codebuild/output/src1196041050/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexzcqoFtcbr4Meta } from "/codebuild/output/src1196041050/src/src/pages/card/create/index.vue?macro=true";
import { default as indexZSkAQaSy94Meta } from "/codebuild/output/src1196041050/src/src/pages/card/index.vue?macro=true";
import { default as completevbGzQrXrBUMeta } from "/codebuild/output/src1196041050/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirmNKpnFUQHw4Meta } from "/codebuild/output/src1196041050/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as registerbOvMTSxoVcMeta } from "/codebuild/output/src1196041050/src/src/pages/contact-form/register.vue?macro=true";
import { default as contact064yyzlWe4Meta } from "/codebuild/output/src1196041050/src/src/pages/contact.vue?macro=true";
import { default as _91id_93yYg63FzVyuMeta } from "/codebuild/output/src1196041050/src/src/pages/coupon/[id].vue?macro=true";
import { default as indexOVk9FVINiyMeta } from "/codebuild/output/src1196041050/src/src/pages/coupon/index.vue?macro=true";
import { default as faql5WXGBa1u6Meta } from "/codebuild/output/src1196041050/src/src/pages/faq.vue?macro=true";
import { default as index1RC8qgyQifMeta } from "/codebuild/output/src1196041050/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93Z8kpC6x8btMeta } from "/codebuild/output/src1196041050/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexnGWD8MX2aJMeta } from "/codebuild/output/src1196041050/src/src/pages/index.vue?macro=true";
import { default as maintenanceW19bPWyIRWMeta } from "/codebuild/output/src1196041050/src/src/pages/maintenance.vue?macro=true";
import { default as completejO7wLYTYV9Meta } from "/codebuild/output/src1196041050/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmexW8gUtn8IMeta } from "/codebuild/output/src1196041050/src/src/pages/membership/confirm.vue?macro=true";
import { default as registervXtI6xbH7AMeta } from "/codebuild/output/src1196041050/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codeSH6NSlp6ZoMeta } from "/codebuild/output/src1196041050/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmL1cPNp1zOTMeta } from "/codebuild/output/src1196041050/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as registerTBKIvvq9OrMeta } from "/codebuild/output/src1196041050/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menuXUAbZv33UrMeta } from "/codebuild/output/src1196041050/src/src/pages/menu.vue?macro=true";
import { default as indexhiKSzZ21PrMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/index.vue?macro=true";
import { default as indexv8hOzP067GMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as samplehUtCZDdfxhMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completeOXWhJRZIVaMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45code4cKfchY3r1Meta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexddpgQtII3xMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexwl4tMDohwkMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as completeStW6hc4GZ0Meta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmSZwhU0BS9VMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexkqFHIDwpdqMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as updateURM5zjwZFHMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirmVwGXTh7KgLMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexyGbgZUCsxtMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updateWlzz9YQOYaMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as completebgTfnBXymvMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as updatekUXpqm3aOEMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexomrmnuH5oDMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_934CPW60RmcPMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as complete0C6FKXHSZDMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirmLrOA1wp2jvMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completegDroW5jBQtMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirmJvhDF8Y3q3Meta } from "/codebuild/output/src1196041050/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentLpn8U2o1llMeta } from "/codebuild/output/src1196041050/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_93YywcJtTLPJMeta } from "/codebuild/output/src1196041050/src/src/pages/notice/[id].vue?macro=true";
import { default as indexs7PjYIoHx9Meta } from "/codebuild/output/src1196041050/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93OOIxmwmr3hMeta } from "/codebuild/output/src1196041050/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementv2mpKUKgLcMeta } from "/codebuild/output/src1196041050/src/src/pages/pass-agreement.vue?macro=true";
import { default as completeMPYuEjdVEMMeta } from "/codebuild/output/src1196041050/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codeiTds9NRIKfMeta } from "/codebuild/output/src1196041050/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passwordHUdvSpQ02CMeta } from "/codebuild/output/src1196041050/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45email3UnSFr8yZlMeta } from "/codebuild/output/src1196041050/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codeSA1ZBEdpyiMeta } from "/codebuild/output/src1196041050/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexkTXYaccZRiMeta } from "/codebuild/output/src1196041050/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45codeBqOyPYOaFaMeta } from "/codebuild/output/src1196041050/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as index1ptIewhbllMeta } from "/codebuild/output/src1196041050/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialI7aPz3wnXzMeta } from "/codebuild/output/src1196041050/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/about-pass.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45aliasKa37lpurvEMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmYpY3U9tmpVMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45alias3V0h3DDVZTMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editYtjelT94isMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmzUZzOLInjIMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexzcqoFtcbr4Meta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completevbGzQrXrBUMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirmNKpnFUQHw4Meta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registerbOvMTSxoVcMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: indexOVk9FVINiyMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: index1RC8qgyQifMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexnGWD8MX2aJMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completejO7wLYTYV9Meta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmexW8gUtn8IMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registervXtI6xbH7AMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codeSH6NSlp6ZoMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmL1cPNp1zOTMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: registerTBKIvvq9OrMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuXUAbZv33UrMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexhiKSzZ21PrMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexv8hOzP067GMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: samplehUtCZDdfxhMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexomrmnuH5oDMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: complete0C6FKXHSZDMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmLrOA1wp2jvMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completegDroW5jBQtMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmJvhDF8Y3q3Meta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentLpn8U2o1llMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexs7PjYIoHx9Meta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93OOIxmwmr3hMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completeMPYuEjdVEMMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codeiTds9NRIKfMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passwordHUdvSpQ02CMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45email3UnSFr8yZlMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codeSA1ZBEdpyiMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexkTXYaccZRiMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codeBqOyPYOaFaMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: index1ptIewhbllMeta || {},
    component: () => import("/codebuild/output/src1196041050/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src1196041050/src/src/pages/tutorial.vue")
  }
]